<template>
    <div class="account-content">  
        <content-loader  v-if="loaded==false"
            viewBox="0 0 520 230"
            primaryColor="#f3f3f3"
            secondaryColor="#cccccc"
            >

            <rect x="0" y="20" rx="5" ry="5" width="520" height="10" />

            <rect x="0" y="60" rx="5" ry="5" width="520" height="10" />

        </content-loader>
        <div class="content-card" :class="{hidden:loaded==false}">
            <!--<div id="error">{{error}}</div>-->

            <form id="payment-form" @submit="paymentConfirm($event)">
                <div id="payment-element">
                    <!-- Elements will create form elements here -->
                </div>
                <button id="submit"><span v-if="language=='fr'">Payer</span><span v-else>Pay</span></button>
                
            </form>

        </div>

        <div id="error">{{error}}</div>
            
                
    </div>
</template>
    
    <script>
    
    import { APIRequest} from "@simb2s/senseye-sdk"
    import Stripe from 'stripe';
    import { ContentLoader } from 'vue-content-loader'
    
    export default {
      name: 'StripeCard',
      components: {
        ContentLoader
      },
      props:['order','amount','language'],
      data() {
        return {
          loaded:false,
          stripeAPIToken: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
          stripe: '',
          elements: '',
          card: '',
          error:'',
          client_secret:'',
          
        }
      },
      watch: {
        amount: async function (val) {
          this.loaded=false
      
        this.stripe = Stripe( this.stripeAPIToken, {locale:this.language} );
        await this.getClientSecret()
        const options = {
            clientSecret: this.client_secret,
            // Fully customizable with appearance API.
            appearance: {/*...*/}
            
        };

        // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3
        this.elements = this.stripe.elements(options);

        // Create and mount the Payment Element
        this.card = this.elements.create('payment', {
          fields: {
            billingDetails: {
              address: {
                country: 'never',
                postalCode: 'never'
              }
            }
          }
        });
        this.card.mount('#payment-element');

        this.loaded=true
        //this.$refs.address1_input.update(this.address1)
        },
       
     
    },
      
      methods:{
        async getClientSecret () {
            let Parameters={}
            Parameters.amount=this.amount
            const response = await APIRequest.create_payment_intent(Parameters);
           
            this.client_secret=response.data.client_secret
        },
        async paymentConfirm(event)
        {
            event.preventDefault()
          this.loaded=false
          const {error} = await this.stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements:this.elements,
            confirmParams: {
              payment_method_data:{
                billing_details:{
                  address:{
                    country:'FR',
                    postal_code:'75000'
                  }
                }
              },
            return_url: process.env.VUE_APP_URL_PRODUCTION+'/payment-validation/'+this.order.id,
            },

        });

        if (error) {
            this.error= error.message;
            this.loaded=true
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
        },
      },
      async mounted()
      {
      
        this.loaded=false
 
        this.stripe = Stripe( this.stripeAPIToken ,{locale:this.language});
        await this.getClientSecret()
        const options = {
            clientSecret: this.client_secret,
            // Fully customizable with appearance API.
            appearance: {/*...*/},
        };

        // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3
        this.elements = this.stripe.elements(options);

        // Create and mount the Payment Element
        this.card = this.elements.create('payment', {
          fields: {
            billingDetails: {
              address: {
                country: 'never',
                postalCode: 'never'
              }
            }
          }
        });
        this.card.mount('#payment-element');

        this.loaded=true
    
      },
      async updated() {
        
      }
      
    }
    </script>
    <style scoped>
  #submit {
    
    
    transition: all 0.5s;
    background: #000 0% 0% no-repeat padding-box;
    border-radius: 17px;
    color: #fff;
    text-align: center;
    margin-top:30px;
    width: 100%;
    display: block;
    font-weight: 300;
    text-decoration: none !important;
    text-transform: none;
    font-size: 16px;
   
    z-index: 2;
   
}
.content-card.hidden {
  position:absolute;
  z-index:-1;
  opacity:0;
}
    </style>