<template>
<div :class="{'account-content':true,'paypal-app':variant=='app'}">
		
		<content-loader  v-if="loaded==false"
      viewBox="0 0 520 50"
      primaryColor="#f6c657"
      secondaryColor="#f6d78f"
    >
      <rect x="0" y="0" rx="25" ry="25" width="520" height="50" />
    </content-loader>

    <div :class="{hidden:loaded==false}">
      <div id="error">{{error}}</div>
     
      <div id="paypal-button-container"></div>
    </div>
			
	</div>
</template>

<script>
import jQuery  from 'jquery';
import {
  // Auth, 
  APIRequest} from "@simb2s/senseye-sdk"
// import Stripe from 'stripe';
import paypal from 'paypal';
import { ContentLoader } from 'vue-content-loader'
import { useCartStore } from '@/store/cartStore.js';
import { useOrderItemStore } from "@/store/orderItemStore.js";

export default {
  name: 'PaypalButton',
  components: {
    ContentLoader
  },
  props:['cart','variant','amount','language'],
  data() {
    return {
      useCart: useCartStore(),
      useOrderItem: useOrderItemStore(),
      displayButton:false,
      user_id:null,
      user:null,
      loaded:false,
      error:'',
      payment:null,
      button:null,
      form:{
        cart:null,
        lang:null,
        amount:null,
        payment_method_id:null,
        name:null,
        surname:null,
        email:null,
        address1:null,
        postcode:null,
        city:null,
        country:null,
      }
    }
  },
  watch: { 
    cart: function() { // watch it
      this.loadButton()
      
    }
  },
  methods:{
    async loadButton() {
      jQuery('#paypal-button-container').html('')
      this.displayButton=false
       this.loaded=false
     
      let payment = await APIRequest.express_checkout_info(this.cart.id)
      this.payment = payment.data
      
      this.form.amount = this.amount
      this.form.cart=this.cart.id
      this.form.lang=this.language
      const __this=this
      this.displayButton=true
      this.button= 	paypal.Buttons({
        fundingSource: paypal.FUNDING.PAYPAL,
            createOrder: function(data, actions) {
              __this.loaded=false
            // This function sets up the details of the transaction, including the amount and line item details.
            return actions.order.create({ 
                purchase_units: [{
                amount: {
                    currency_code: "EUR",
                    value: __this.amount
                }
                }]
            });
            },
            onClick:function(){
                this.loaded=false
            },
            onApprove: function(data, actions) {
            // This function captures the funds from the transaction.
            return actions.order.capture().then(async function(details) {
                // This function shows a transaction success message to your buyer.
                
                __this.form.payment_method_id=details.id;
                __this.form.name=details.payer.name.given_name;
                __this.form.surname=details.payer.name.surname;
                __this.form.address1=details.purchase_units[0].shipping.address.address_line_1;
                __this.form.postcode=details.purchase_units[0].shipping.address.postal_code;
                __this.form.city=details.purchase_units[0].shipping.address.admin_area_2;
                __this.form.country=details.purchase_units[0].shipping.address.country_code;
                __this.form.email=details.payer.email_address;
                // alert('Transaction completed by ' + details.payer.name.given_name);
                // on envoie le paiement
                try {
                 
                  let order = await APIRequest.express_checkout_payment(__this.form);
                  
                  
                  if(__this.variant=="app") {
                    document.location.href="senseyeapp://sucess-paypal-app"
                  }
                
                  else {
                    await APIRequest.delete('carts',localStorage.cart_senseye_id)
                    await __this.useCart.resetStore()
                    await __this.useOrderItem.resetStore()
                    localStorage.removeItem('cart_senseye_id')
                    __this.$router.push({ name: 'payment-confirmation', params: { id: order.data.order } })
                  }
                  
                // on redirect vers la confirmation de payment
                } catch (error) {
                  __this.loaded=true
                  
                    if(__this.variant=="app") {
                      document.location.href="senseyeapp://error-paypal-app"
                    }
                  
                  else {
                    console.log('error paypal')
                    console.log(error)
                  }
                }
            
                __this.loaded=true
            });
            },
            onCancel:() => {
              __this.loaded=true
            },
            onError:(err) => {
              console.log('une erreur sest produite')
              __this.loaded=true
              this.error=err.message

            }
        }).render('#paypal-button-container');
        
        this.loaded=true
        
    }
  },
  async created()
  {
    this.loadButton()
  }
  
}
</script>
<style scoped>

.hidden {
  position:absolute;
  opacity:0;
}
.account-content.paypal-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: 'center';
}
.account-content
{
  padding : 5px 10px;
}
.connect-text {
  font-size:12px;
}
</style>