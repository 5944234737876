<template>

    <div class="checkout coordonnees">
        <div class="breadrcrumb-checkout">
            <template v-for="(s, index) in steps" :key="index">
                <a  @click="changeStep(index)" class="step-checkout" :class="{active: step == index}" :id="`step-checkout-step${s.index}`" :data-step="s.index">{{ s.index }}. <span v-if="language=='fr'">{{ s.txt }}</span><span v-else>{{ s.txt_en }}</span></a>
                    <span v-if="index !== 2">></span>
            </template>
        </div>
    <br /><br />
    <div class="coordonnees-content" v-if="step==1">
        <div class="express-checkout" v-if="displayExpressCheckout">
            <PaypalButton :language="language" :cart="cart" :amount="total" variant="web" />
        </div>
        <div class="or" v-if="displayExpressCheckout"></div>

        <content-loader  v-if="loaded==false || loadingCoordinates || checkoutMounted==false || orderReviewLoading"
            viewBox="0 0 520 650"
            primaryColor="#f3f3f3"
            secondaryColor="#cccccc"
        >

            <rect x="0" y="20" rx="11" ry="11" width="250" height="22" />
            <rect x="0" y="70" rx="17" ry="17" width="520" height="34" />
            <rect x="0" y="130" rx="11" ry="11" width="250" height="22" />
            <rect x="0" y="180" rx="17" ry="17" width="250" height="34" />
            <rect x="270" y="180" rx="17" ry="17" width="250" height="34" />
            <rect x="00" y="240" rx="17" ry="17" width="520" height="34" />
            <rect x="00" y="300" rx="17" ry="17" width="520" height="34" />
            <rect x="0" y="360" rx="17" ry="17" width="250" height="34" />
            <rect x="270" y="360" rx="17" ry="17" width="250" height="34" />
            <rect x="0" y="420" rx="17" ry="17" width="250" height="34" />
            <rect x="270" y="420" rx="17" ry="17" width="250" height="34" />
            <rect x="0" y="500" rx="17" ry="17" width="200" height="34" />
            <rect x="320" y="500" rx="17" ry="17" width="200" height="34" />
            <rect x="00" y="580" rx="17" ry="17" width="520" height="34" />
        
        </content-loader>

        <div id="step-1" v-else>
            <h2 v-if="language=='fr'" class="step-title" >Coordonnées <span class="asterisque">* Champs obligatoires</span>
                <span class="content-guest" v-if="displayGuest">
                    <br /><a  @click="displayLoginToTrue" class="connect">Vous avez déjà un compte ? Se connecter</a>
                </span>
            </h2>
            <h2 v-else class="step-title" >Contact <span class="asterisque">* Mandatory fields</span>
                <span class="content-guest" v-if="displayGuest">
                    <br /><a  @click="displayLoginToTrue" class="connect">You already have an account? Connect</a>
                </span>
            </h2>
            
            <div class="step">
                
                <div class="user-form">
                    <div class="popup-login" id="popup-login-account" v-if="displayLogin">
                        <div class="login-form">
                            <form v-on:submit.prevent="login_account()">
                                
                            <input type="text" id="login_account" :class="{error:this.errors_login.username}" v-on:change="assignValue('username', $event)" autocomplete="on" placeholder="Email">
                            
                            <div class="form-line">
                                <input type="password" id="password_account" :class="{error:this.errors_login.password}" v-on:change="assignValue('password', $event)"  autocomplete="on" placeholder="******">
                                <div id="error-message-account" class="error-form-message">{{errorAccount}}</div>
                            </div>
                            <button  id="login_button_account">Connexion</button>
                            
                            </form>
                            <center>
                                <a v-if="language=='fr'" class="forgot" @click="displayForgotPassword()">Mot de passe oublié ?</a>
                                <a v-else class="forgot" @click="displayForgotPassword()">Forgotten password?</a>
                            </center>
                        </div>
                        <br />
                    </div>
                    <div class="form-line">
                        <input :disabled="user!=null" type="email" :class="{error:errors.email}" name="email" :value="email" @change="changeEmail($event)" autocomplete="on" id="email-input" placeholder="Email *" />
                        <span class="error-form-message" v-if="errors.email">{{ errors.email }}</span>
                       
                    </div>
                    <a v-if="user!=null" class="deco" @click="logoutCheckout()">
                        <span v-if="language=='fr'">Se déconnecter</span>
                        <span v-else>Disconnect</span>
                    </a>
                    <span id="error-message-register-checkout">{{errorRegister}}</span>

                    <button class="login-form button" v-if="accountExists" @click="checkoutWithoutConnect()">
                        <span v-if="language=='fr'">Continuer sans se connecter</span>
                        <span v-else>Continue without login</span>
                    </button>
                </div>
            </div>
            <h2 v-if="language=='fr'" class="step-title">Adresse de livraison <span class="asterisque">* Champs obligatoires</span></h2>
            <h2 v-else class="step-title">Delivery address <span class="asterisque">* Mandatory fields</span></h2>
            <div class="step">
                <div class="checkout-form">
                    <form @submit="checkForm" ref="form">
                        <input name="id_address" id="id_address" v-on:change="assignValue('selectedAddress', $event)" type="hidden" />
                        <div id="contentselectaddress" v-if="addresses.length>0 && user!=null">
                            <label v-if="language=='fr'" @click="displayAddresses()" class="label-address">Choisir une adresse enregistrée</label>
                            <label v-else @click="displayAddresses()" class="label-address">Choose a saved address</label>
                            <div v-if="displayAddress">
                            <div  v-for="(address,index) in addresses" :key="address.id" >
                                <div @click="selectAddress(address.id,address.address1,address.postcode,address.city,address.country,index)" class="select-address" :data-id="address.id" :data-address1="address.address1" :data-postcode="address.postcode" :data-city="address.city" :data-country="address.country">
                                    {{address.name}}
                                </div>
                            </div>
                            </div>
                            <br />
                        </div>
                        <div class="form-line">
                            <div class="input small">
                                <input v-if="language=='fr'" type="text"   :class="{error:errors.surname}"  v-on:change="assignValue('surname', $event)" :value="surname" autocomplete="on" name="surname" id="surname-input" placeholder="Nom *" />
                                <input v-else type="text"   :class="{error:errors.surname}"  v-on:change="assignValue('surname', $event)" :value="surname" autocomplete="on" name="surname" id="surname-input" placeholder="Surname *" />
                                <span class="error-form-message" v-if="errors.surname">{{ errors.surname }}</span>
                            </div>
                            <div class="input small">
                                <input v-if="language=='fr'" type="text" :class="{error:errors.name}" v-on:change="assignValue('name', $event)" :value="name" autocomplete="on" name="name" id="name-input" placeholder="Prénom *" />
                                <input v-else type="text" :class="{error:errors.name}" v-on:change="assignValue('name', $event)" :value="name" autocomplete="on" name="name" id="name-input" placeholder="Name *" />
                                <span class="error-form-message" v-if="errors.name">{{ errors.name }}</span>
                            </div>
                        </div>
                        <div class="form-line">
                            <vue-google-autocomplete

                                v-if="language=='fr'"

                                id="map"
                                
                                :value="address1_current"
                                classname="form-control"
                                :class="{error:errors.address1}" 
                                placeholder="Adresse *"
                                @input="(event) => inputAddress(event)"
                                v-on:placechanged="getAddressData"
                                v-on:inputChange="changeAddress"
                                ref="address1_input"
                            >
                            </vue-google-autocomplete>
                            <vue-google-autocomplete

                                v-else

                                id="map"
                                
                                :value="address1_current"
                                classname="form-control"
                                :class="{error:errors.address1}" 
                                placeholder="Address *"
                                @input="(event) => inputAddress(event)"
                                v-on:placechanged="getAddressData"
                                v-on:inputChange="changeAddress"
                                ref="address1_input"
                            >
                            </vue-google-autocomplete>

                            <span class="error-form-message" v-if="errors.address1">{{ errors.address1 }}</span>
                        </div>
                        <div class="form-line">
                            <input type="text"  v-if="language=='fr'" :class="{error:errors.address2}"  v-on:change="assignValue('address2', $event)" :value="address2" autocomplete="on" id="address2-input" placeholder="Appartement, Bâtiment, suite, etc. (facultatif)" />
                            <input type="text"  v-else :class="{error:errors.address2}"  v-on:change="assignValue('address2', $event)" :value="address2" autocomplete="on" id="address2-input" placeholder="Appartment, Building, floor, etc. (optional)" />
                            <span class="error-form-message" v-if="errors.address2">{{ errors.address2 }}</span>
                        </div>
                        <div class="form-line">
                            <div class="input small">
                                <input type="text"  v-if="language=='fr'" :class="{error:errors.postcode}"  autocomplete="on" v-on:change="assignValue('postcode', $event)" :value="postcode" id="postcode-input" placeholder="Code postal *" />
                                <input type="text"  v-else :class="{error:errors.postcode}"  autocomplete="on" v-on:change="assignValue('postcode', $event)" :value="postcode" id="postcode-input" placeholder="Postcode *" />
                                <span class="error-form-message" v-if="errors.postcode">{{ errors.postcode }}</span>
                            </div>
                            <div class="input small">
                                <input type="text"  v-if="language=='fr'" :class="{error:errors.city}"  autocomplete="on" v-on:change="assignValue('city', $event)" :value="city" id="city-input" placeholder="Ville *" />
                                <input type="text"  v-else :class="{error:errors.city}"  autocomplete="on" v-on:change="assignValue('city', $event)" :value="city" id="city-input" placeholder="City *" />
                                <span class="error-form-message" v-if="errors.city">{{ errors.city }}</span>
                            </div>
                        </div>
                        <div class="form-line">
                            <div class="input small">
                                <input type="text"  v-if="language=='fr'" :class="{error:errors.country}"  class="small"  autocomplete="on" v-on:change="assignValue('country', $event)" :value="country" id="country-input" placeholder="Pays *" />
                                <input type="text"  v-else :class="{error:errors.country}"  class="small"  autocomplete="on" v-on:change="assignValue('country', $event)" :value="country" id="country-input" placeholder="Country *" />
                                <span class="error-form-message" v-if="errors.country">{{ errors.country }}</span>
                            </div>
                            <div class="input small">
                                <input type="text" v-if="language=='fr'"  :class="{error:errors.phone}"   @input="assignPhoneValue" :value="phone" autocomplete="on" class="small phone-input" name="phone" id="phone-input" placeholder="Téléphone *" />
                                <input type="text" v-else  :class="{error:errors.phone}"   @input="assignPhoneValue" :value="phone" autocomplete="on" class="small phone-input" name="phone" id="phone-input" placeholder="Phone *" />
                                <span class="error-form-message" v-if="errors.phone">{{ errors.phone }}</span>
                            </div>    
                        </div>
                        
                    </form>
                    
                </div>
                <div class="save-address" v-if="user!=null">
                    <input type="checkbox" @change="setSaveAddress()"/> <span v-if="language=='fr'">Enregister l'adresse</span><span v-else>Save address</span>
                </div>
                
            </div>
            <div class="step"  v-if="language=='fr'">
                <div class="delivery-title">Tarif de livraison</div>
                <span class="delivery-cost">Livraison offerte<br />
                    <div class="desc-delivery">Colissimo - Expedition en 48h<br />Préparation de la commande entre 6 à 8 semaines</div>
                </span>
                
                <div id="delivery_methods_table" v-if="delivery_methods">
                    <table class="table shipping-methods" v-if="delivery_methods.length>0">
                        <tr v-for="(method, index) in delivery_methods" :key="index">
                            <td>
                            <input type="radio" @click="chooseShipping(method.id)" name="shipping_method" class="choice_shipping" :value="method.cost" :data-id="method.id" />
                            </td>
                            <td>{{method.name}}</td>
                            <td>{{method.cost}}€</td>
                        </tr>
                    </table>
                    <input type="hidden" id="delivery_method" v-on:change="assignValue('delivery_method', $event)" />
                    <input type="hidden" id="delivery_amount" v-on:change="assignValue('delivery_amount', $event)" />
                
                </div>
    
                <br /><br />
                <!--
                    <PickupList :address="address1" :postcode="postcode" :city="city" />
                -->
                <input type="hidden" id="pickup_id" v-model="selectedPickup" />
            </div>
            <div v-else>
                <div class="delivery-title">Delivery cost</div>
                <span class="delivery-cost">Free delivery<br />
                <div class="desc-delivery">Colissimo - Shipping within 48h<br />Order manufacturing between 6 and 8 weeks</div>
                </span>
                
                <div id="delivery_methods_table" v-if="delivery_methods">
                    <table class="table shipping-methods" v-if="delivery_methods.length>0">
                    <tr v-for="(method, index) in delivery_methods" :key="index">
                        <td>
                        <input type="radio" @click="chooseShipping(method.id)" name="shipping_method" class="choice_shipping" :value="method.cost" :data-id="method.id" />
                        </td>
                        <td>{{method.name}}</td>
                        <td>{{method.cost}}€</td>
                    </tr>
                    </table>
                    <input type="hidden" id="delivery_method" v-on:change="assignValue('delivery_method', $event)" />
                    <input type="hidden" id="delivery_amount" v-on:change="assignValue('delivery_amount', $event)" />
                
                </div>
    
                <br /><br />
                <!--
                    <PickupList :address="address1" :postcode="postcode" :city="city" />
                -->
                <input type="hidden" id="pickup_id" v-model="selectedPickup" />
            </div>
            <div class="bottom">
                <span v-if="language=='fr'" class="label">
                    Sous-total du Panier
                    <!-- <span class="tva">TVA Incluse</span> -->
                    <span class="delivery" style="display:none;">
                        Livraison : 
                        
                        <span id="delivery_text">6 à 8</span>
                        semaines</span>
                </span>
                <span v-else class="label">
                    Cart subtotal
                    <!-- <span class="tva">TVA Incluse</span> -->
                    <span class="delivery" style="display:none;">
                        Delivery : 
                        
                        order manufacturing between 6 and 8 weeks</span>
                </span>
                <span class="total_cart"><span id="total_cart">{{total}}</span>€</span>       
                <button id="process_order_checkout" @click="processOrder()" v-if="step==1">
                    <span v-if="language=='fr'">Paiement</span>
                    <span v-else>Payment</span>
                </button>
            </div>
        </div>
    </div>

   
            
    <div class="coordonnees-content" id="popup-payment" v-if="step==2">
        <content-loader  v-if="loaded==false || loadingCoordinates || checkoutMounted==false || orderReviewLoading"
            viewBox="0 0 520 650"
            primaryColor="#f3f3f3"
            secondaryColor="#cccccc"
        >

            <rect x="0" y="20" rx="11" ry="11" width="250" height="22" />
            <rect x="0" y="70" rx="17" ry="17" width="520" height="34" />
            <rect x="0" y="130" rx="11" ry="11" width="250" height="22" />
            <rect x="0" y="180" rx="17" ry="17" width="250" height="34" />
            <rect x="270" y="180" rx="17" ry="17" width="250" height="34" />
            <rect x="00" y="240" rx="17" ry="17" width="520" height="34" />
            <rect x="00" y="300" rx="17" ry="17" width="520" height="34" />
            <rect x="0" y="360" rx="17" ry="17" width="250" height="34" />
            <rect x="270" y="360" rx="17" ry="17" width="250" height="34" />
            <rect x="0" y="420" rx="17" ry="17" width="250" height="34" />
            <rect x="270" y="420" rx="17" ry="17" width="250" height="34" />
            <rect x="0" y="500" rx="17" ry="17" width="200" height="34" />
            <rect x="320" y="500" rx="17" ry="17" width="200" height="34" />
            <rect x="00" y="580" rx="17" ry="17" width="520" height="34" />
        
        </content-loader>

        <div class="content" v-else>
            <h2 v-if="language=='fr'" class="step-title">Mode de paiement</h2>
            <h2 v-else class="step-title">Payment method</h2>
            <div class="step">
                <div class="total-payment">
                    <div v-if="language=='fr'" class="label">Montant total : </div><div v-else class="label">Total amount : </div><span id="amount">{{total}}</span>€
                </div>
                <div id="content-payment">
                    <!--<iframe v-if="order.id" allowpaymentrequest="true" id="iframe_payment" :src="'https://api.sens-eye.fr/checkout/payment/'+ this.order.id"></iframe>-->
            
                    <button id="process_order_checkout2" @click="processFreeOrder()" v-if="(freeOrder || total<=0) && loaded">
                        <span v-if="language=='fr'">Confirmer la commande</span>
                        <span v-else>Confirm order</span>
                    </button>
                    
                    <template v-else>
                        <StripeCard v-if="order" :language="language" :order="order" :amount="total" />
                    </template>

                </div>
            </div>
        </div>
    </div>

</div>
       
</template>

<script>
// Import Components
import PaypalButton from '../../../components/elements/PaypalButton.vue'
import PickupList from '../../elements/PickupList.vue'
import StripeCard from './StripeCard.vue'
import { ContentLoader } from 'vue-content-loader'

// Import Helpers
import VueGoogleAutocomplete from 'vue-google-autocomplete' 

// Import data
import stepsData from "../../../data/checkout/steps_data"
export default {
    name: "CheckoutCoordinates",
    props: ["total","orderReviewLoading","language","user","freeOrder","step","displayExpressCheckout","cart","loaded","displayGuest","displayLogin","errorAccount","selectedAddress",
            "displayAddress","delivery_methods","delivery_method","delivery_amount","onlycollab", "order", "addresses",
            "address1","address2","postcode","city","country","phone", "email", "surname", "name", "errorRegister", "errors","accountExists","saveAddress"],
    components:{
        PickupList, 
        PaypalButton,
        VueGoogleAutocomplete,
        ContentLoader,
        StripeCard
    },
    watch: {
        address1: function (val) {
        this.address1_current = val
        //this.$refs.address1_input.update(this.address1)
        },
       
     
    },
    computed: {
       
    },

    updated() {
     

        if(this.step==1 && this.$refs.address1_input) {
            this.address1_current=this.address1
            this.$refs.address1_input.update(this.address1)
        }
    },
    mounted(){
       
        this.address1_current=this.address1

        this.loadingCoordinates=false
    },
    methods:{
        async checkoutWithoutConnect(){
            await this.$emit('checkoutWithoutConnectEv')
            await this.$emit('checkFormEv')
            
            if(!this.errors.length)  {
                this.$emit('processOrderEv')
            }
        },
        setSaveAddress() {
            this.$emit('setSaveAddressEv', !this.saveAddress)
        },
        changeAddress(){
            
           
            
            this.address1_current=this.$refs.address1_input.autocompleteText
            if(this.address1_current=="") {
             
            }
            this.$emit('assignValueEv', 'address1',this.$refs.address1_input.autocompleteText)
        },
        
        changeStep(step, breadcrumb)
        {   
            this.$emit('changeStepEv', step, breadcrumb)
        },
        displayLoginToTrue()
        {
            this.$emit('displayLoginToTrueEv')
        },
        login_account()
        {
            this.errors_login = {};
           
            if (!this.username) {
                this.errors_login.username='Votre adresse mail est requise.';
            }
            if (!this.password) {
                this.errors_login.password='Le mot de passe est requis';
            }
            if(!this.errors_login.length)  {
                this.$emit('login_accountEv')
            }
            else {
                this.errorAccount="Les champs ne doivent pas être vides."
            }
            
        },
        displayForgotPassword()
        {
            this.$emit('displayForgotPasswordEv')
        },
        logoutCheckout() {
            this.$emit('logoutCheckoutEv')
        },
        displayAddresses()
        {
            this.$emit('displayAddressesEv')
        },    
        async processFreeOrder()
        {
            await this.$emit('processFreeOrderEv')

            
        },    
        async processOrder()
        {
            await this.$emit('checkFormEv')
            if(!this.errors.length)  {
                this.$emit('changeStepEv', 2, false)
            }
        },
        changeEmail(e)
        {
            this.$emit('changeEmailEv', e.target.value)
        },
        selectAddress(id,address1,postcode,city,country,index)
        {
            this.address1_current=address1
            this.$emit('selectAddressEv', id,address1,postcode,city,country,index)
        },
        inputAddress(e)
        {
            this.$emit('assignValueEv', "address1", e.target.value)
        },
        getAddressData(addressData, placeResultData, id)
        {
            this.$emit('getAddressDataEv', addressData, placeResultData, id)
        },
        assignValue(link,e)
        {
            this.$emit('assignValueEv', link, e.target.value)
        },
        assignPhoneValue(e)
        {
            
            this.$emit('assignPhoneValueEv', e)
        }
    },
    data(){
        const {steps} = stepsData;
        return{
            address1_current:'',
            steps,
            loadingCoordinates:true,
            errors_login:{}
        }
    }
}
</script>

<style scoped>
.user-form input {
    font-size:13px;
}
.checkout-form input {
    font-size:13px;
}
#process_order_checkout2 {
    margin-top: 30px;
    transition: all 0.5s;
    background: #000 0% 0% no-repeat padding-box;
    border-radius: 17px;
    color: #fff;
    text-align: center;
    width: 100%;
    display: block;
    font-weight: 300;
    text-decoration: none !important;
    text-transform: none;
    font-size: 16px;
}
.save-address {
    text-align: left;
    font-size:12px;
    margin-bottom:10px;
    margin-top:5px;
}
.login-form.button {
    margin-top:10px;
}
@media screen and (max-width:760px) {
    
}
input.error {
    color:#000;
}
.desc-delivery {
    font-size:10px;
    color:#000;
}
a.connect
{
    color: #0000FF;
    font-size: 11px;
}
.checkout .express-checkout::after
{
	
	background: #fff;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    top: -12px;
    left: 50%;
	font-weight:700;
    transform: translateX(-50%);
    content: "Paiement express";
 
}
.checkout .express-checkout
{
	
	border: 1px solid black;
    /* padding: 15px; */
    padding-top: 10px;
    padding-bottom: 10px;
	position:relative;
}
table.shipping-methods tr,
table.shipping-methods td,
table.shipping-methods
{
	border:0;
}
#error-message-register-checkout,.error
{
	color:orange;
}
.asterisque
{
    color:grey;
    margin-top:10px;
	font-size:10px;
    font-weight: 400;
    font-style: italic;
}
#contentselectaddress
{
	text-align: left;
	font-size:13px;
}
.select-address
{
	position:relative;
	
	font-size:13px;
	margin-top:5px;
	cursor:pointer;
	transition:all 0.3s;
	padding:5px 20px;
}

.select-address:hover
{
	background:rgba(0,0,0,0.05)
}

.select-address.active:before
{
	background:#000;
}
.select-address:before
{
	position:absolute;
	left:3px;
	border-radius:50%;
	border:1px solid black;
	background:transparent;
	width:12px;
	height:12px;
	content:' ';
	top:7px;
}
.label-address
{
	color:#3572d4;
	cursor:pointer;
	font-size:13px;
}
      .popup-login
        {
            animation: fadeanimation 0.4s ease;
        }
    .form-line {
        display:flex;
        position:relative;
        justify-content: space-between;
    }

    
    .checkout-form input::focus,
    .user-form input::focus
    {
        border: 1px solid black !important;
    }

    .error {
        border:1px solid red !important;
    }
    .error-form-message {
        color:red;
        font-size:10px;
        position:absolute;
        bottom:5px;
        left:0;
    }
    .checkout-form input,
    .user-form input
    {
    margin-bottom: 20px !important;
        display: block;
        background: #fff !important;
        border: 1px solid #ccc;
        border-radius: 0 !important;
        width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .checkout-form .input.small,
    .user-form .input.small
    
    {
        position:relative;
        width: 49% !important;
    }

    @media screen and (max-width:760px)
    {
        
        body .checkout-form .input.small
        {
            width:100% !important;
        }
        .form-line {
            flex-direction: column;
        }
    }
    a.deco {
        color: #3572d4;
cursor: pointer;
font-size: 13px;
    }

</style>