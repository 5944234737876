<template>
    <div class="page-template-checkout">
        <div class="checkout-wrapper">
        
            <div v-if="cart_items.length==0 && loaded==true">Vous n'avez aucun produit dans votre panier.</div>
            <div v-else>
                <Overlay :language="language" v-if="visible" :title="title" :visible="visible" @remove="visible=false"/>
         
                <CheckoutCoordinates

                    :language="language"
                    :freeOrder="freeOrder"
                    :saveAddress="saveAddress"
                    :step="step"
                    :displayExpressCheckout="displayExpressCheckout"
                    :cart="cart"
                    :loaded="loaded"
                    :displayGuest="displayGuest"
                    :orderReviewLoading="orderReviewLoading"
                    :displayLogin="displayLogin"
                    :errorAccount="errorAccount"
                    :selectedAddress="selectedAddress"
                    :displayAddress="displayAddress"
                    :delivery_methods="delivery_methods"
                    :delivery_method="delivery_method"
                    :delivery_amount="delivery_amount"
                    :onlycollab="onlycollab"
                    :total="total"
                   
                    :order="order"
                    :addresses="addresses"
                    :address1="address1"
                    :address2="address2"
                    :postcode="postcode"
                    :city="city"
                    :country="country"
                    :phone="phone"
                    :email="email"
                    :surname="surname"
                    :name="name"
                    :user="user"
                    :errorRegister="errorRegister"
                    :accountExists="accountExists"
                    :errors="errors"
                    :addressesLoaded="addressesLoaded"
                    :checkoutMounted="checkoutMounted"

                    @processFreeOrderEv="processFreeOrderConfirm"
                    @setSaveAddressEv="setSaveAddress"
                    @logoutCheckoutEv="logoutCheckout"
                    @displayLoginToTrueEv="displayLogin=true"
                    @changeStepEv="changeStep"
                    @login_accountEv="login_account"
                    @displayForgotPasswordEv="displayForgotPassword"
                    @changeEmailEv="changeEmail"
                    @displayAddressesEv="displayAddresses"
                    @selectAddressEv="selectAddress"
                    @getAddressDataEv="getAddressData"
                    @processOrderEv="processOrder"
                    @assignValueEv="assignValue"
                    @assignPhoneValueEv="assignPhoneValue"
                    @checkFormEv="checkForm"
                    @checkoutWithoutConnectEv="setCheckoutWithoutLogin"
                />

                <CheckoutOrderReview

                    :totalOrder="totalOrder"
                    :language="language"
                    :totalLoaded="totalLoaded"
                    :loaded="loaded"
                    :cart_items="cart_items"
                    :photos="photos"
                    :promotionError="promotionError"
                    :displayPromotion="displayPromotion"
                    :total="total"
                    :amount_promotion="amount_promotion"

                    @clickToggleReviewEv="clickToggleReview"
                    @plusCartItemEv="plusCartItem"
                    @minusCartItemEv="minusCartItem"
                    @applyPromotionEv="applyPromotion"
                    @unApplyPromotionEv="unApplyPromotion"
                    @promocodeEv="assignValue"
                    @orderReviewLoadedEv="orderReviewLoaded"
                />
            </div>  
        </div>

    <br /><br /><br /><br />
    <Footer :language="language" :displayModalMenu="displayModalMenu" />
  </div>
  
</template>

<script>
import sendinblue  from 'sendinblue';
import fbq  from 'fbq';
import Footer from '../components/layout/Footer.vue'
import jQuery  from 'jquery';
import {Auth, APIRequest, conversionApi} from "@simb2s/senseye-sdk";
import emitter from 'tiny-emitter/instance'
import CheckoutCoordinates from "../components/children/Checkout/CheckoutCoordinatesComponent.vue"
import CheckoutOrderReview from "../components/children/Checkout/CheckoutOrderReviewComponent.vue"
import Overlay from "../components/overlay/OverlayComponent.vue"
import { useCartStore } from '@/store/cartStore.js';
import { useAuthStore } from '@/store/authStore.js';
import { useOrderItemStore } from "@/store/orderItemStore.js";
import { useMeta } from 'vue-meta'
import { ConversionSourceProduct } from '@getbrevo/brevo';

const phoneRegex = /((\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}/;
export default {
  name: 'Checkout',
  components: {
    Footer,
    CheckoutCoordinates,
    CheckoutOrderReview,
    Overlay
  },
  data() {
    return {
        useCart: useCartStore(), 
        useAuth: useAuthStore(),
        useOrderItem: useOrderItemStore(),
      title: "",
      totalOrder:0,
      promotionId:null,
      freeOrder:false,
      saveAddress:false,
      addressesLoaded:false,
      totalLoaded:false,
      errors:{},
      visible: false,
      randomNumber:1,
      checkoutMounted:false,
      displayLogin:false,
      password_register:'',
      promocode:'',
      address1:'',
      orderReviewLoading:true,
      address2:'',
      postcode:'',
      selectedPickup:'',
      city:'',
      country:'',
      name:'',
      errorRegister:'',
      accountExists:false,
      accountAlreadyExists:true,
      error:'',
      errorAccount:'',
      surname:'',
      email:'',
      phone:'',
      username:null,
      password:null,
      available_methods:[],
      cart:null,
      cart_weight:0,
      delivery_methods:[],
      delivery_method:null,
      delivery_amount:null,
      loaded:false,
      weight_cart:0,
      phone:'',
      order:null,
      cart_items:[],
      order_items:[],
      onlycollab:true,
      photos:[],
      step:1,
      displayGuest:true,
      addresses:[],
      displayAddress:false,
      selectedAddress:'none',
      displayPromotion:false,
      promotionError:'',
      total:0.00,
      amount_promotion:0,
      checkoutLoaded:false,
      autocomplete:null,
      checkoutWithoutLogin:false,
      displayExpressCheckout:false,
    }
  },
  props:["displayModalMenu","language"],
  watch:
  {
    country:function(){
        this.loadPickups();
        this.updateCartAddress();
    },
    postcode:function(){
        this.loadPickups();
    },
    city:function(){
        this.loadPickups();
    },
    address1:function(){
        this.loadPickups();
    },
    phone:function(){
        if(!this.phone.match(phoneRegex)){
            //this.showModal("Format du téléphone incorrect")
            //this.phone = "";
            this.errors.phone="Format du téléphone incorrect"
        }
        else {
            this.errors.phone=null
        }
    },
    total:function() {

        
      
        let str_total = this.total.toString()
        if(str_total.includes("-"))
        {
            str_total=str_total.replace('-','')
            this.total=str_total
            this.$forceUpdate()
        }
    },

    totalOrder:function() {

        
      
        let str_total = this.totalOrder.toString()
        if(str_total.includes("-"))
        {
            str_total=str_total.replace('-','')
            this.totalOrder=str_total.toFixed(2)
            this.$forceUpdate()
        }
        },
    
  },
  methods:{
    async userLoginCheckout(user) {
      
        if(user!=null)
        {

            this.user=await Auth.get_user(user.username) 
            localStorage.id_user_senseye_auth=this.user.id;
            this.errorRegister=''
            this.cart=await this.useCart.cartCopy
            this.cart_items=this.useOrderItem.cartItem;
            //this.order=await APIRequest.detail('orders',localStorage.order_senseye_id)
        
            await this.createOrder()    
            localStorage.order_senseye_id=this.order.id;
            
            
            this.errorRegister=""
            // ICI verif store
            this.errorAccount="";

        
            

            if(this.checkoutWithoutLogin==false)
            {
                
                this.email=this.user.email
                if(this.user.phone!='') {
                    this.phone=this.user.phone
                }
                this.name=this.user.name
                this.surname=this.user.surname
                this.getAddresses()
                //await this.updateOrder()
                this.error=''
                this.displayLogin=false
                this.displayGuest=false
            }
            else {
                
                /// HEEEERE
                let user_data = await Auth.get_user(this.email)
                // here update order and cart with id user
                localStorage.id_user_senseye_auth = user_data.id
                this.user=null
                await this.displayPayment(false);
                // here update order and cart with id user
                //localStorage.id_user_senseye_auth = this.user.id
                
                
                
            }
            this.loaded=true
            this.totalLoaded=true
        }
        else
        {
        
            this.errorAccount="Mot de passe ou email incorrect.";
            this.loaded=true
            this.totalLoaded=true
        }
    },
    orderReviewLoaded()
    {
        this.orderReviewLoading=false
    },
    setSaveAddress(value) {
        this.saveAddress=value
        
    },
    async minusCartItem(e, id_item,quantity){
     if(quantity==1) {
      
      this.deletingItem=true
     }
     await this.useOrderItem.decreaseOrderItemCollab(id_item);
      this.useOrderItem.updateOrderItemCollab()
    },
    async plusCartItem(e, id_item){
      await this.useOrderItem.increaseOrderItemCollab(id_item);
      this.useOrderItem.updateOrderItemCollab()
    },

    async setCheckoutWithoutLogin() {
        console.log('SET DCHECKOTU WITHOUT LOGG')
        this.checkoutWithoutLogin=true
        
        // this.loaded=true
        // this.totalLoaded=true
        //emitter.emit('login-checkout', process.env.VUE_APP_CONNECT_ACCOUNT_LOGIN, process.env.VUE_APP_CONNECT_ACCOUNT_PASSWORD);

        
        // on get les infos user du mail 
        

        
        await this.createOrder()
       
        
       // await this.changeStep(2)
       



    },
   
    async logoutCheckout() {
        this.loaded=false
        this.user=null
        this.displayGuest=true

        await this.useAuth.handleLogoutCheckout()

        this.loaded=true
        
        //await this.getCart()
        
    },
    changeEmail(email) { 
        this.email = email;
        sendinblue.identify(this.email);
    },

    assignPhoneValue(e)
    {
        this.phone=e.target.value
    },
    
    async assignValue(link,value)
    {
        switch(link){
            case "username":
                this.username = value
                break;
            case "password":
                this.password = value
                break;
            case "selectedAddress":
                this.selectedAddress = value
                break;
            case "surname":
                var event_name = "update_surname"

                var properties = {
                "email": '',
                "FIRSTNAME": '',
                "LASTNAME": '',

                }

                console.log('SEND SIB EVENT')
                var event_data = {
                    "data": {
                        "surname": value
                    
                    }
                }

                sendinblue.track(
                    event_name, /*mandatory*/
                    properties, /*optional*/
                    event_data /*optional*/
                )
                this.surname = value
                break;
            case "name":
                var event_name = "update_name"

                var properties = {
                "email": '',
                "FIRSTNAME": '',
                "LASTNAME": '',

                }

                console.log('SEND SIB EVENT')
                var event_data = {
                    "data": {
                        "name": value
                    
                    }
                }

                sendinblue.track(
                    event_name, /*mandatory*/
                    properties, /*optional*/
                    event_data /*optional*/
                )
                this.name = value
                break;
            case "address1":
                this.address1 = value

                var event_name = "update_address1"

                var properties = {
                "email": '',
                "FIRSTNAME": '',
                "LASTNAME": '',
                
                }

                console.log('SEND SIB EVENT')
                var event_data = {
                    "data": {
                        "address1": value
                    
                    }
                }
                
                sendinblue.track(
                    event_name, /*mandatory*/
                    properties, /*optional*/
                    event_data /*optional*/
                )
    
                break;
            case "address2":
                this.address2 = value
                var event_name = "update_address2"

                var properties = {
                "email": '',
                "FIRSTNAME": '',
                "LASTNAME": '',

                }

                console.log('SEND SIB EVENT')
                var event_data = {
                    "data": {
                        "address2": value
                    
                    }
                }

                sendinblue.track(
                    event_name, /*mandatory*/
                    properties, /*optional*/
                    event_data /*optional*/
                )
                break;
            case "postcode":
                var event_name = "update_postcode"

                var properties = {
                "email": '',
                "FIRSTNAME": '',
                "LASTNAME": '',

                }

                console.log('SEND SIB EVENT')
                var event_data = {
                    "data": {
                        "postcode": value
                    
                    }
                }

                sendinblue.track(
                    event_name, /*mandatory*/
                    properties, /*optional*/
                    event_data /*optional*/
                )
                this.postcode = value
                break;
            case "city":
                var event_name = "update_city"

                var properties = {
                "email": '',
                "FIRSTNAME": '',
                "LASTNAME": '',

                }

                console.log('SEND SIB EVENT')
                var event_data = {
                    "data": {
                        "city": value
                    
                    }
                }

                sendinblue.track(
                    event_name, /*mandatory*/
                    properties, /*optional*/
                    event_data /*optional*/
                )
                this.city = value
                break;
            case "country":
                var event_name = "update_country"

                var properties = {
                "email": '',
                "FIRSTNAME": '',
                "LASTNAME": '',

                }

                console.log('SEND SIB EVENT')
                var event_data = {
                    "data": {
                        "country": value
                    
                    }
                }

                sendinblue.track(
                    event_name, /*mandatory*/
                    properties, /*optional*/
                    event_data /*optional*/
                )
                this.country = value
                break;
            case "phone":
             var event_name = "update_phone"

                var properties = {
                "email": '',
                "FIRSTNAME": '',
                "LASTNAME": '',

                }

                console.log('SEND SIB EVENT')
                var event_data = {
                    "data": {
                        "phone": value
                    
                    }
                }

                sendinblue.track(
                    event_name, /*mandatory*/
                    properties, /*optional*/
                    event_data /*optional*/
                )
                this.phone = value;
                break;
            case "delivery_method":
                this.delivery_method = value
                break;
            case "delivery_amount":
                this.delivery_amount = value
                break;
            case "promocode":
                this.promocode = value
                break;
        }
    },
  
    getAddressData(addressData, placeResultData, id) {
        let street_number='';
        let street = '';
        for(let i=0;i<placeResultData.address_components.length;i++) {
            if(placeResultData.address_components[i].types.includes("street_number")!=false) {
                street_number=placeResultData.address_components[i].short_name
            }
            else if(placeResultData.address_components[i].types.includes("route")!=false) {
                street=placeResultData.address_components[i].long_name
            }
            else if(placeResultData.address_components[i].types.includes("locality")!=false) {
                this.city=placeResultData.address_components[i].long_name;
            }   
            else if(placeResultData.address_components[i].types.includes("country")!=false) {
                this.country=placeResultData.address_components[i].long_name;
            }
            else if(placeResultData.address_components[i].types.includes("postal_code")!=false) {
                this.postcode=placeResultData.address_components[i].long_name;
            }

        }
      
      let all_street='';
      if(street_number!='') {
        all_street+=street_number+' '
      }
      if(street!='') {
        all_street+=street
      }
      this.address1=all_street;
      
      
      
    },
    displayForgotPassword()
    {
        this.$emit('displayForgotPasswordEv')
    },
    selectPickup(id)
    {
        this.selectedPickup=id
    },
    processFreeOrder(breadcrumb)
    {
        console.log('process free order')
        // here changestep
        this.loaded=false
        this.totalLoaded=false
        this.displayPaymentFreeOrder(breadcrumb);
    },
    processOrder(breadcrumb=false)
    {
        // here changestep
        this.loaded=false
        this.totalLoaded=false
   
        this.displayPayment(breadcrumb);
    },
    selectAddress(id,address1,postcode,city,country,index){
   
        this.address1=""
        if(this.selectedAddress==id)
        {
            this.selectedAddress='none'
        }
        else
        {
            this.selectedAddress=id
            this.address1=address1
            this.postcode=postcode
            this.city=city
            this.country=country
            let addresses=jQuery('.select-address')
            jQuery('.select-address').attr('class','select-address')
            jQuery(addresses[index]).attr('class','select-address active')
        }
    },
    loadPickups()
    {
    },
    async checkForm() {
        console.log('check form')
        this.errors = {};
        if (this.name=='') {
            this.errors.name='Le Nom est requis.';
        }
        if (this.surname=='') {
            this.errors.surname='Le Prénom est requis.';
        }
        
       

        if (this.address1=='') {
            this.errors.address1="L'adresse est requise.";
        }
        if (this.postcode=='') {
            this.errors.postcode='Le code postal est requis.';
        }
        if (this.city=='') {
            this.errors.city='Le ville est requise.';
        }
        if (this.country=='') {
            this.errors.country='Le pays est requis.';
        }
        if (this.phone=='') {
            this.errors.phone='Le téléphone est requis.';
        }
        if (this.email=='') {
            this.errors.email="L'adresse mail est requise.";
        }
    },
    async changeStep(step, breadcrumb=false)
    {
        this.loaded=false
        if(step!=0) {
           
            if(step==2)
            {
                await this.checkForm(step)
              
                if(!Object.keys(this.errors).length)  {
                    
                    if(this.freeOrder==true) {
                       
                        if(breadcrumb==true) {

                            await this.processFreeOrder(true)
                            this.step=step
                            this.displayExpressCheckout=false
                        }
                        else {
                         await this.processFreeOrder()
                         this.step=step
                    this.displayExpressCheckout=false
                        }
            
                    }
                    else {
                        if(breadcrumb==true) {

                        await this.processOrder(true)
                        this.step=step
                    this.displayExpressCheckout=false
                        }
                        else {
                        await this.processOrder()
                        this.step=step
                    this.displayExpressCheckout=false
                        }
                    }
                    
                    
                   
                }
                else
                {
                    this.loaded=true
                }
            }
            else
            {
                this.step=step
                this.displayExpressCheckout=true

                this.loaded=true
            }
            jQuery('html, body').animate({
                scrollTop: 0
            }, 500);   
        }
        else {
            emitter.emit('display-cart');
        }           
    },
    async createAddress()
    {
        //this.checkForm()
        

        
        try {
            let Parameters = {};
            Parameters.name = "Domicile";
            Parameters.first_name = this.surname;
            Parameters.last_name = this.name;
            Parameters.phone = this.phone;
            Parameters.address1 = this.address1;
            Parameters.address2 = this.address2;
            Parameters.postcode = this.postcode;
            Parameters.city = this.city;
            Parameters.default_address=true;
            Parameters.country = this.country;
            

            //if(this.saveAddress==true) {
                if(localStorage.id_user_senseye_auth)
                {
                    Parameters.user={};
                    Parameters.user.id=localStorage.id_user_senseye_auth;
                }
            //}
           
            let address=APIRequest.create('addresses', Parameters);
            
            return address;
        } catch (error) {
            this.error="Une erreur s'est produite"
        }
    
        
    },
    showModal(title){
        this.visible = true;
        this.title = title;
    },
    async updateOrder() {
        console.log('update order')
        this.loaded=false
        fbq('track', 'AddPaymentInfo', {currency: "EUR", value: this.cart.amount});
        await conversionApi.addEvent('AddPaymentInfo')
        this.order_items=this.order.order_items;

        let order_items=JSON.parse(localStorage.order_items_senseye)

        // let new_order_items=[]
        // for(let i=0;i<this.cart_items.length;i++)
        // {
        //     let item=this.cart_items[i]
        //     delete item.id
        //     item.glass={id:this.cart_items[i].glass.id}
        //     new_order_items.push(item)
        // }
        let Parameters = {};
        Parameters.amount = this.order.amount;
        Parameters.woo_order =  this.order.woo_order;
        Parameters.shapediver_id =  this.order.shapediver_id;
        Parameters.address = this.order.address;
        Parameters.phone =this.phone;
        Parameters.lang = this.language
        Parameters.platform='website'
        //Parameters.order_items = new_order_items;
        Parameters.delivery_method = this.delivery_method;
        Parameters.delivery_amount = this.delivery_amount;
        Parameters.promotion = this.order.promotion;
        Parameters.cart = {};
        Parameters.cart.id = this.cart.id;
        Parameters.pickup_point=this.selectedPickup;
        Parameters.user={};
        Parameters.user.id=localStorage.id_user_senseye_auth;

        if(this.selectedAddress && this.selectedAddress!='none')
        {
            Parameters.address.id = this.selectedAddress;             
        }
        else
        {              
            let address=await this.createAddress();
            let id_address=address.id;
            Parameters.address = {};
            Parameters.address.id = id_address;
            this.address1=address.address1
        }
        try {
            //this.order=await APIRequest.update('orders',localStorage.order_senseye_id, Parameters)
            this.loaded=true
            
        } catch (error) {
            this.error="Une erreur s'est produite"
            this.loaded=true
        }

        
    },
    async reloadUserContent()
    {
        console.log('reload user contennnnnt checkout')
        console.log(this.useAuth.userLoginCheckout)
        console.log(this.accountAlreadyExists)
        if(this.useAuth.userLoginCheckout!=null)
        {
            if(this.accountAlreadyExists==false)
            {
                console.log('send email register')
            
                await APIRequest.sendMailRegister(this.useAuth.userLoginCheckout.id);
            }
        }
        
        
    },

    async displayPaymentFreeOrder(breadcrumb) { 
        console.log('display payment freeorder')
        this.loaded=false
        this.error=""



        if(jQuery('#pickup-list').is(':visible') && this.selectedPickup=='')
        {
            this.showModal('Vous devez sélectionner un point relais !')
        }
        else
        {

            
            if(this.address1!='' && this.postcode !='' && this.city!='' && this.country!='' && this.phone!='')
            {
                if((localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth) ||  this.checkoutWithoutLogin==true)
                { 

                    console.log("OKK TEST")
                
                    try {
                        await this.createOrder()    
                        
                        //await this.updateOrder()
                    } catch (error) {
                        this.error="Une erreur s'est produite"
                    }
                    
                    if(breadcrumb==false && this.error=="") {
                       
                        //this.changeStep(2)
                    }
                    else {
                       
                    }
                    // this.loaded=true
                    // this.totalLoaded=true

                    if(this.checkoutWithoutLogin==true) {

                        console.log('OK TEST 2')
                       
                        localStorage.removeItem('token_senseye_auth')
                        localStorage.removeItem('refresh_token_senseye_auth')
                        localStorage.removeItem('id_user_senseye_auth')
                        this.checkoutWithoutLogin=false
                        
                    }
                }
                else
                {
                    console.log('on regisyer')
                    await this.registerUser();
                }
            }
            else
            {
                this.showModal('Tous les champs sont obligatoires !')
                this.loaded=true
                this.totalLoaded=true
            }
        }
    },
    async displayPayment(breadcrumb) { 
        this.error=""
        if(jQuery('#pickup-list').is(':visible') && this.selectedPickup=='')
        {
            this.showModal('Vous devez sélectionner un point relais !')
        }
        else
        {
            
            if(this.address1!='' && this.postcode !='' && this.city!='' && this.country!='' && this.phone!='')
            {
                if((localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth) ||  this.checkoutWithoutLogin==true)
                {   
                    
                    try {
                        await this.createOrder()    
                        localStorage.order_senseye_id=this.order.id;
                        //await this.updateOrder()
                    } catch (error) {
                        this.error="Une erreur s'est produite"
                    }
                    
                    if(breadcrumb==false && this.error=="") {
                       
                        //this.changeStep(2)
                    }
                    else {
                        
                    }
                    this.loaded=true
                    this.totalLoaded=true

                    if(this.checkoutWithoutLogin==true) {
                      
                        localStorage.removeItem('token_senseye_auth')
                        localStorage.removeItem('refresh_token_senseye_auth')
                        localStorage.removeItem('id_user_senseye_auth')
                        this.checkoutWithoutLogin=false
                        
                    }
                }
                else
                {
                    await this.registerUser();
                }
            }
            else
            {
                this.showModal('Tous les champs sont obligatoires !')
                this.loaded=true
                this.totalLoaded=true
            }
        }
    },
    async registerUser()
    {
        // Ici verif store
        let password= Math.random().toString(36).substring(7);
        this.password_register=password

        // ICI 

        emitter.emit('register',this.email,password,this.name,this.phone,this.surname,this.email)
    },
    async login_account(){  
        this.loaded=false
        this.totalLoaded=false
        
        this.$emit('loginCheckoutEv',{username:this.username, password:this.password})
       
        //await emitter.emit('login-checkout', this.username, this.password);
        // if(user==null) {
           
        // }
        // else {
            
        // }
    },
    async updateCartAddress(){
      let Parameters={};
      Parameters.country=this.country;
      try {
          this.available_methods=await APIRequest.list_shipping_options(Parameters);
      } catch (error) {
          this.available_methods=[]
      }
      if(this.available_methods==undefined)
      {
          this.available_methods=[]
      }  
      let cart_items = await this.useOrderItem.cartItem         
      let nb_items=0;
      for(var p=0;p<cart_items.length;p++)
      {
          nb_items=nb_items*1+cart_items[p].quantity*1;
      }
      this.weight_cart=nb_items*0.25;
      this.delivery_methods=[];
      let method_nb=0;
      if(this.available_methods.length>0)
      {
        for(var i=0;i<this.available_methods.length;i++)
        {
            if(this.available_methods[i].enabled==true)
            {
            let shipping_rates=this.available_methods[i].settings.shipping_rates.value;
            let shipping_cost=null;
            for(var j=0;j<shipping_rates.length;j++)
            {
                if(shipping_rates[j].min_weight<=this.weight_cart && shipping_rates[j].max_weight>this.weight_cart)
                {
                    shipping_cost=shipping_rates[j].price;
                }
            } 
            if(shipping_cost!=null)
            {
                this.delivery_methods[method_nb]={};
                this.delivery_methods[method_nb].cost=shipping_cost;
                this.delivery_methods[method_nb].id=this.available_methods[i].method_id;
                this.delivery_methods[method_nb].name=this.available_methods[i].method_title;
                method_nb++;
            }
            }
        }    
        this.delivery_method=this.delivery_methods[0].id;
        this.delivery_amount=this.delivery_methods[0].cost;  
      }
    },
    async chooseShipping(id_method, method_amount)
    {
      this.delivery_amount=id_method;
      this.delivery_amount=method_amount;
      this.cart=await this.useCart.cartCopy
      let Parameters = {};
      Parameters.amount = this.cart.amount;
      Parameters.delivery_method = this.delivery_method; 
      Parameters.delivery_amount = this.delivery_amount;
      Parameters.cart_items = this.cart.cart_items;
      Parameters.promotion = this.cart.promotion;
      Parameters.user=this.cart.user;
      await APIRequest.update('carts', localStorage.cart_senseye_id, JSON.stringify(Parameters))
      this.loaded=true                              
    },
    processFreeOrderConfirm()
    {
        this.$router.push({ name: 'payment-validation', params: { id: this.order.id } })
    },
    
    async unApplyPromotion() {
        this.loaded=false
        this.totalLoaded=false
        await this.createOrder()
        this.order.promotion=null

        // let new_order_items=[]
        // for(let i=0;i<this.cart_items.length;i++)
        // {
        //     let item=this.cart_items[i]
        //     delete item.id
        //     delete item.cart
        //     item.glass={id:this.cart_items[i].glass.id,name:this.cart_items[i].glass.name}
        //     new_order_items.push(item)
        // }

        // let Parameters = {};
        // Parameters.amount = this.cart.amount;
       
        // Parameters.promotion = null;
        
        // Parameters.address = this.order.address;
        // Parameters.phone = this.order.phone;
        // Parameters.user=this.order.user;
        // //Parameters.order_items = new_order_items
        // this.order=await APIRequest.update('orders',this.order.id,JSON.stringify(Parameters))
        
        
        
        await this.useCart.unApplyPromotion()
        
        this.total=this.cart.amount.toFixed(2)
        //this.totalOrder=this.cart.amount.toFixed(2)
        this.amount_promotion=null
        let rand = Math.floor((Math.random()*1000000)+1);
        this.randomNumber=rand
        if(document.getElementById('iframe_payment'))
        {
            let iframepaymenturl=document.getElementById('iframe_payment').src;
            document.getElementById('iframe_payment').src=iframepaymenturl;
        }
        this.freeOrder=false
        this.promotionError= ""
        this.displayPromotion=false
        this.promocode=''
        this.promotionId=null
        this.totalLoaded=true
        
        this.loaded=true

    },
    async applyPromotion() {
        this.loaded=false
        this.totalLoaded=false
        
        let promocode=this.promocode;
        let promotion_id=null;
        if(promocode!="")
        {
            let promotionexist=false;
            //this.order=await APIRequest.detail('orders',localStorage.order_senseye_id)
            let promotions=await APIRequest.list('promotions')
            promotions=promotions[0]              
            for(var i=0;i<promotions.length;i++)
            {
                if(promocode.toLowerCase().replace(' ','')==promotions[i].code.toLowerCase().replace(' ',''))
                {
                    promotionexist=true;
                    promotion_id=promotions[i].id;
                    if(promotions[i].active==true)
                    {

                    // ICI PROMO
                        
                    if(promotions[i].promotion_type=='creator')
                    {
                        if(promotions[i].use_count<promotions[i].usage_limit)
                        {
                            let apply=false
                            let total_promo=0
                            for(let d=0;d<promotions[i].products.length;d++)
                            {


                                for(let c=0;c<this.cart_items.length;c++)
                                {
                                    if(this.cart_items[c].glass.id==promotions[i].products[d].id)
                                    {
                                        apply=true
                                        await this.useCart.applyPromotion(promotion_id)


                                        this.promotionId=promotion_id
                                        
                                        if(this.cart.promotion)
                                        {

                                            let amount_promotion=(((this.cart_items[c].amount/100) * promotions[i].products[d].comission) / 100) * promotions[i].amount;
                                           
                                            total_promo+=amount_promotion
                                            this.displayPromotion=true
                                           
                                            let rand = Math.floor((Math.random()*1000000)+1);
                                            this.randomNumber=rand
                                            if(document.getElementById('iframe_payment'))
                                            {
                                                let iframepaymenturl=document.getElementById('iframe_payment').src;
                                                document.getElementById('iframe_payment').src=iframepaymenturl;
                                            }
                                            
                                        
                                        }  
                                        await this.createOrder()
                                        //this.order=await APIRequest.update('orders',this.order.id,JSON.stringify(Parameters))
                                        this.promotionError= ""
                                        this.loaded=true
                                        this.totalLoaded=true
                                    }
                                    
                                }
                            }

                            if(apply==false)
                            {
                                this.showModal("Le code promo n'est pas valide pour les produits sélectionnés");
                                this.promotionError= "Le code promo n'est pas valide pour les produits sélectionnés"
                                this.loaded=true
                                this.totalLoaded=true
                            }
                            else
                            {
                                this.amount_promotion=total_promo.toFixed(2)
                            }

                            
                        }
                        else
                        {
                            this.showModal("Le code promo n'est plus valide");
                            this.promotionError= "Le code promo n'est plus valide"
                            this.loaded=true
                            this.totalLoaded=true
                        }
                        
                    }
                    else
                    {
                        let new_order_items=[]
                        for(let p=0;p<this.cart_items.length;p++)
                        {
                            let item=this.cart_items[p]
                            delete item.id
                            delete item.cart
                            item.glass={id:this.cart_items[p].glass.id,name:this.cart_items[p].glass.name}
                            item.tint={id:this.cart_items[p].tint.id,name:this.cart_items[p].tint.name,price:this.cart_items[p].tint.price}
                            new_order_items.push(item)
                        }

                        // let Parameters = {};
                        // Parameters.amount = this.order.amount;
                        // Parameters.delivery_amount = this.order.delivery_amount;
                        // Parameters.delivery_method = this.order.delivery_method;
                        // Parameters.woo_order = this.order.woo_order;
                        // Parameters.promotion = {};
                        // Parameters.promotion.id = promotion_id;
                        // Parameters.shapediver_id = this.order.shapediver_id;
                        // Parameters.address = this.order.address;
                        // Parameters.phone = this.order.phone;
                        // Parameters.user=this.order.user;
                        //Parameters.order_items = new_order_items;
                       
                        await this.useCart.applyPromotion(promotion_id)


                        this.promotionId=promotion_id
                        
                        if(this.cart.promotion)
                        {

                            let amount_promotion=(this.cart.amount/100) * this.cart.promotion.amount;
                            if(this.cart.promotion.amount==100)
                            {
                               
                                var new_total=0;
                            }
                            else {
                                var new_total=this.cart.amount - amount_promotion;
                            }

                            //this.totalOrder=new_total.toFixed(2)

                            
                            this.displayPromotion=true
                            //this.total=new_total.toFixed(2)
                            this.amount_promotion=amount_promotion.toFixed(2)

                            new_total=new_total*1+this.delivery_amount*1;

                            if(this.cart.promotion.amount==100)
                            {
                                
                                this.freeOrder=true
                            }
                            else {
                                let rand = Math.floor((Math.random()*1000000)+1);
                                this.randomNumber=rand
                                if(document.getElementById('iframe_payment'))
                                {
                                    let iframepaymenturl=document.getElementById('iframe_payment').src;
                                    document.getElementById('iframe_payment').src=iframepaymenturl;
                                }
                            }
                           
                        }  
                        await this.createOrder()
                        //this.order=await APIRequest.update('orders',this.order.id,JSON.stringify(Parameters))
                        this.promotionError= ""
                        this.loaded=true
                        this.totalLoaded=true
                    }

                        

                       
                    }
                    else
                    {
                        this.showModal("Le code promo n'est plus valide");
                        this.promotionError= "Le code promo n'est plus valide"
                        this.loaded=true
                        this.totalLoaded=true
                    }
                }
            }
            if(promotionexist==false)
            {
                this.showModal("Le code promo n'existe pas");
                this.loaded=true
                this.totalLoaded=true
                this.promotionError= "Le code promo n'existe pas"
            }                                     
        }
        else
        {
            this.showModal('Le champs Code promotionnel est vide !');
            this.promotionError= 'Le champs est vide'
            this.loaded=true
            this.totalLoaded=true
        }
    },
    parseJwt (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    },
    displayAddresses()
    {
        if(this.displayAddress==false)
        {
            this.displayAddress=true;
        }
        else
        {
            this.displayAddress=false;
        }
    },
    async getAddresses()
    {

        let addresses=await APIRequest.list('addresses',localStorage.id_user_senseye_auth,null, 5)
        this.addresses=addresses[0]
        if(this.addresses.length>0)
        {
            if(this.address1 =="")
            {
                this.address1=this.addresses[0].address1
            }
            if(this.postcode =="")
            {
                this.postcode=this.addresses[0].postcode
            }
            if(this.city =="")
            {
                this.city=this.addresses[0].city
            }
            if(this.country =="")
            {
                this.country=this.addresses[0].country
            }
    
            this.displayAddress=false;
        }
        this.addressesLoaded=true
    },
    async getPhotos() {
        this.onlycollab=true;
        this.photos=[]
        for (let i=0;i<this.cart_items.length;i++)
        {
            if(this.cart_items[i].glass.type=="accessoire")
            {
                this.photos[i]=this.cart_items[i].glass.photos[0]
            }
            else if(this.cart_items[i].glass.type=="collaboration")
            {
                if(this.cart_items[i].product_variation_item!=null)
                {
                    this.photos[i]=this.cart_items[i].product_variation_item.photos[0]
                }
                else
                {
                    this.photos[i]=this.cart_items[i].glass.photos[0]
                }
            }
            else
            {
                this.onlycollab=false;
                if(this.cart_items[i].tint!==null)
                {
                    var request = "?glass="+this.cart_items[i].glass.id+"&color="+this.cart_items[i].color.id+'&texture='+this.cart_items[i].texture.id+'&branch='+this.cart_items[i].branch.id+'&tint='+this.cart_items[i].tint.id;
                }
                else
                {
                    var request = "?glass="+this.cart_items[i].glass.id+"&color="+this.cart_items[i].color.id+'&texture='+this.cart_items[i].texture.id+'&branch='+this.cart_items[i].branch.id+'&tint=5';
                }
                if(this.cart_items[i].texture!==null) {
                    request+="&texture="+this.cart_items[i].texture.id
                }
                if(this.cart_items[i].texture_style!==null) {
                    request+="&texture_style="+this.cart_items[i].texture_style.id
                }
                let photos= await APIRequest.list_with_request('photos',request)
                let photo=photos[0]
                this.photos[i]=photo[0].photo
            }
        }
    },
    async calculateTotal()
    {
        
        this.totalLoaded=false
        this.total=this.cart.amount.toFixed(2)
        this.totalOrder=this.cart.amount.toFixed(2)
        if(this.cart.promotion)
        {
            
            if(this.cart.promotion.promotion_type=='creator')
            {
                        
                let apply=false
                let total_promo=0
                for(let d=0;d<this.cart.promotion.products.length;d++)
                {

                    for(let c=0;c<this.cart_items.length;c++)
                    {
                        if(this.cart_items[c].glass.id==this.cart.promotion.products[d].id)
                        {
                            apply=true
                           
                            console.log('comission ?')
                            console.log(this.cart.promotion.products[d])

                            let amount_promotion=(((this.cart_items[c].amount/100) * this.cart.promotion.products[d].comission) / 100) * this.cart.promotion.amount;
                                         
                           
                           
                            total_promo+=amount_promotion
                            

                            //this.totalOrder=new_total.toFixed(2)
                            
                            //this.total=new_total.toFixed(2)
                            
                           
                        }
                    }
                }
                if(apply==false)
                {
                    this.displayPromotion=true
                    this.amount_promotion=0
                      
                    this.promotionError= "Le code promo n'est pas valide pour les produits sélectionnés"
                    //this.unApplyPromotion()
                }
                else 
                {
                    console.log('total promo')
                    console.log(total_promo)
                    this.amount_promotion=total_promo.toFixed(2)
                    var new_total=this.cart.amount - total_promo;
                    this.total=new_total.toFixed(2)
                    jQuery('.order-review #total').html(new_total.toFixed(2));
                    jQuery('.order-review #cart-st').html(new_total.toFixed(2)+'€');
                    jQuery('.order-review #subtotal').html(this.cart.amount.toFixed(2));
                    jQuery('.order-review #total_promotion').html(total_promo.toFixed(2));
                    this.displayPromotion=true
                    let delivery_cost=this.delivery_amount;
                    new_total=new_total*1+delivery_cost*1;
                    jQuery('.checkout .bottom .total_cart #total_cart').html(new_total.toFixed(2));
                    jQuery('.total-payment #amount').html(new_total.toFixed(2));
                }
            }
            else
            {
                let amount_promotion=(this.cart.amount/100) * this.cart.promotion.amount;
                if(this.cart.promotion.amount==100)
                {
                    this.freeOrder=true
                    var new_total=0;
                }
                else {
                    var new_total=this.cart.amount - amount_promotion;
                }

                //this.totalOrder=new_total.toFixed(2)
                
                this.total=new_total.toFixed(2)
                this.amount_promotion=amount_promotion.toFixed(2)
                jQuery('.order-review #total').html(new_total.toFixed(2));
                jQuery('.order-review #cart-st').html(new_total.toFixed(2)+'€');
                jQuery('.order-review #subtotal').html(this.cart.amount.toFixed(2));
                jQuery('.order-review #total_promotion').html(amount_promotion.toFixed(2));
                this.displayPromotion=true
                let delivery_cost=this.delivery_amount;
                new_total=new_total*1+delivery_cost*1;
                jQuery('.checkout .bottom .total_cart #total_cart').html(new_total.toFixed(2));
                jQuery('.total-payment #amount').html(new_total.toFixed(2));
            }
          
        }
        else
        {
            jQuery('.order-review #total').html(this.cart.amount.toFixed(2));
            jQuery('.order-review #cart-st').html(this.cart.amount.toFixed(2)+'€');
            let delivery_cost=jQuery('#delivery_amount').val();
            let new_amount=this.cart.amount*1+delivery_cost*1;
            jQuery('.checkout .bottom .total_cart #total_cart').html(new_amount);
            jQuery('.total-payment #amount').html(this.cart.amount);
        }
        this.totalOrder=this.cart.amount
        this.totalLoaded=true
        
    },
    async updateOrderItemsWithOrder() {
        let items=JSON.parse(localStorage.order_items_senseye);
        for(var i=0;i<items.length;i++)
        {
            console.log('order item')
            console.log(items[i])
            let order_item= await APIRequest.detail('order-items',items[i].id);     
            let Parameters = {...order_item,order:{id:localStorage.order_senseye_id}};
           
            order_item= await APIRequest.update('order-items',order_item.id,JSON.stringify(Parameters))                  
        } 
        this.loaded=true
    },
    async createOrder()
    {
        console.log('create order')
        this.loaded=false
        let new_order_items=[]
        for(let i=0;i<this.cart_items.length;i++)
        {
            let item=this.cart_items[i]
            delete item.id
            delete item.cart
            item.glass={id:this.cart_items[i].glass.id,name:this.cart_items[i].glass.name}
            item.tint={id:this.cart_items[i].tint.id,name:this.cart_items[i].tint.name,price:this.cart_items[i].tint.price}
            if(this.cart_items[i].order_item_customizations.length>0)
            {
                let order_item_custom=[]
                for(let z=0;z<this.cart_items[i].order_item_customizations.length;z++)
                {
                    let order_item_custom_item =this.cart_items[i].order_item_customizations[z]
                    
                    
                    order_item_custom_item.model_part.glass={id:this.cart_items[i].order_item_customizations[z].model_part.glass.id}
                    order_item_custom.push(order_item_custom_item)
                }
                item.order_item_customizations=order_item_custom
            }
            new_order_items.push(item)
        }
        let Parameters = {};
        if(this.address1!='' && this.postcode!='')
        {
            let address=await this.createAddress();
            let id_address=address.id;
            Parameters.address = {};
            Parameters.address.id = id_address;
        }
        Parameters.amount = this.cart.amount;
        Parameters.shapediver_id = 0;
        Parameters.lang = this.language
        Parameters.woo_order = 0;
        // Parameters.cart = {};
        // Parameters.cart.id = this.cart.id;
        Parameters.delivery_amount = this.cart.delivery_amount;
        Parameters.delivery_method = this.cart.delivery_method;
        Parameters.phone = jQuery('#phone-input').val();
        Parameters.order_items = new_order_items;
        Parameters.platform='website'

        if(this.promotionId)
        {
            console.log('on a une promo')
            Parameters.promotion = {id:this.promotionId}
        }
        else
        {
            Parameters.promotion = this.cart.promotion;
        }
        
        
        if(localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth && this.checkoutWithoutLogin==false)
        {
            Parameters.user={};
            Parameters.user.id=localStorage.id_user_senseye_auth;
            this.displayLogin=false
            this.displayGuest=false
            try {
                let user=this.parseJwt(localStorage.token_senseye_auth);
                this.user=await Auth.get_user(user.username)
                localStorage.id_user_senseye_auth=this.user.id;
                this.email=this.user.email
                this.name=this.user.name
                this.surname=this.user.surname
                if(this.user.phone!='') {
                    this.phone=this.user.phone
                }
                sendinblue.identify(this.email);
                
                this.getAddresses()
                this.error=''
                
            } catch (error) {
                this.loaded=true
                this.error='Une erreur est survenue'
            }
        }
        else if(localStorage.id_user_senseye_auth)
        {
            Parameters.user={};
            Parameters.user.id=localStorage.id_user_senseye_auth;
        }
        else if(this.checkoutWithoutLogin==true)
        {

            console.log('CHECKOUT WITHOUT LOGGGGGG')
            // on get le user
        
            let Parameters2 = {};
            Parameters2.username = process.env.VUE_APP_CONNECT_ACCOUNT_LOGIN;
            Parameters2.password = process.env.VUE_APP_CONNECT_ACCOUNT_PASSWORD;
     
            let user_token = await Auth.login(Parameters2)

            console.log('LOGIN ADMIN OK')
            console.log(user_token)

            localStorage.token_senseye_auth=user_token.token

            let user=await this.useAuth.getUser(this.email);

            console.log(user)
            // On applique l'id de l'user a la commande

            Parameters.user={};
            Parameters.user.id=user.id;
        }

       
        
        this.order=await APIRequest.create('orders', JSON.stringify(Parameters))
        localStorage.order_senseye_id=this.order.id;
        this.totalLoaded=true
        this.loaded=true 
    
    },
    async getCart() {
        if(this.useCart.cartLoaded==false) {
           
            await this.useCart.getCart();
            await this.useOrderItem.getOrderitemListCollab();
            
        }
        if(this.useOrderItem.orderItemLoaded==false) {
          
            //await this.useOrderItem.getOrderitemListCollab();
            
        }

        if(this.useOrderItem.loadingOrderItems==false) {

            
           // this.loaded=true
        

           

            if(await this.useOrderItem.cartItem.length>0 && await this.useCart.cartCopy != null) {
                
                this.cart=await this.useCart.cartCopy
                this.displayExpressCheckout=true
                fbq('track', 'InitiateCheckout', {currency: "EUR", value: this.cart.amount});
                await conversionApi.addEvent('InitialeCheckout')
                
                
                this.cart_items=this.useOrderItem.cartItem;
                this.order_items=[];
                
                for(let i=0;i<this.cart_items.length;i++)
                {
                    let order_item={};
                    order_item.id=this.cart_items[i].id;
                    this.order_items[i]=order_item;
                }
                localStorage.order_items_senseye=JSON.stringify(this.order_items);  
                //await this.getPhotos()
                await this.calculateTotal() 
                await this.createOrder()   
                
                localStorage.order_senseye_id=this.order.id;             
                //await this.updateOrderItemsWithOrder()

                //this.loaded=true

            }
            else {
                this.showModal('Aucun panier');
           
                this.loaded=true
                
            }
            

        }
        else {
            
            //this.loaded=true
               
        }

 
    },
    clickToggleReview() {
        if(jQuery('.order-review #order-review-content').is(':visible'))
        {
            jQuery('.order-review h2.contenumob').removeClass('open');
            jQuery('.order-review #order-review-content').slideUp();
            jQuery('.order-review .subtotals').slideUp();
            jQuery('.order-review  .total').slideUp();
            jQuery('.order-review .message').slideUp();
        }
        else
        {
            jQuery('.order-review h2.contenumob').addClass('open');
            jQuery('.order-review #order-review-content').slideDown();
            jQuery('.order-review .subtotals').slideDown();
            jQuery('.order-review  .total').slideDown();
            jQuery('.order-review .message').slideDown();
        }
    },
    async updateCartInfos() {
        console.log('update carrrrrrrrrrrrty infos')
        //this.loaded=false
        if(await this.useOrderItem.cartItem.length>0 && await this.useCart.cartCopy != null) {
            this.cart=await this.useCart.cartCopy

            console.log('HEEEERE????????')

            console.log(this.useCart.cartCopy.amount)

            this.cart_items=this.useOrderItem.cartItem;
            this.order_items=[];
            for(let i=0;i<this.cart_items.length;i++)
            {
                let order_item={};
                order_item.id=this.cart_items[i].id;
                this.order_items[i]=order_item;
            }
            localStorage.order_items_senseye=JSON.stringify(this.order_items);  
            //await this.getPhotos()
            await this.calculateTotal()
            //await this.createOrder()       
            //localStorage.order_senseye_id=this.order.id;             
            // await this.updateOrderItemsWithOrder()
            //this.loaded=true
            if(this.step==1) {
                this.displayExpressCheckout=true
            }
            else if(this.step==2 && this.checkoutWithoutLogin==false)
            {
                this.loaded=true
            }
            
            console.log(this.cart_items)
            //
            
            
        }
        else
        {
            if(this.loaded==true) {
                this.showModal('Aucun panier');
            }
        }
        
    },
    async userRegisterCheckout(user) {

        console.log('registered user checkout')
        if(user!=null)
        {
            this.accountAlreadyExists=false
            this.errorRegister=""

            
                console.log('new userrrrrrr')
                // ICI verif store

                this.$emit('loginCheckoutEv',{username:this.email, password: this.password_register})

                // ICI ON ENVOIE LE mail 

                
         
            // emitter login /  logged in checkout-truc
        }
        else
        {
            console.log('set checkout without log')
          
            this.accountAlreadyExists=true
            //this.errorRegister="Il semblerait que vous ayez déjà un compte avec cet email.";
            await this.setCheckoutWithoutLogin()
            
            //this.loaded=true
        }
       
    },
    async userLogout()
    {
        console.log('user logout')
        this.loaded=false
        await this.createOrder()
        this.user=null
        this.displayLogin=true
        this.displayGuest=true
        this.loaded=true
    },
  },
  created() {
    useMeta({
    title: "VISAGES - Checkout",
    
    htmlAttrs: { lang: this.language, amp: true },
    
})

    if(this.language=='fr')
        {
      

  
    document.querySelector('meta[name="description"]').setAttribute('content',"Finalisez votre commande.")
        }
        else
        {
       
    document.querySelector('meta[name="description"]').setAttribute('content',"Finish your order.")
      
        }
  },
  async mounted()
  { 
    this.loaded=false
    this.useOrderItem.$subscribe(async (mutation, state) => {
        if (mutation.storeId == "orderItem") {

            console.log('update cart infos checkout store')
            console.log(mutation)
            await this.updateCartInfos()

             if(this.useOrderItem.loadingOrderItems==false && this.checkoutMounted==false) {

                
                //await this.getCart()
                
               
                this.checkoutMounted=true
             }
            
        }
    })
    emitter.on('selectpickup', function (id) {
        this.selectedPickup(id)
    }, this);
  


    

    if(this.useAuth.isAuth==true && this.checkoutWithoutLogin==false){
        this.user=await this.useAuth.getUser(localStorage.username_user_senseye_auth)
        this.email=this.user.email
         if(this.user.phone!='') {
            this.phone=this.user.phone
        }
        this.name=this.user.name
        this.surname=this.user.surname
        this.getAddresses()
        sendinblue.identify(this.email);
        //await this.updateOrder()
        this.error=''
        this.displayLogin=false
        this.displayGuest=false
    }
    
    await this.getCart()

    let cart_items_datalayer=[]
    for(let i=0;i<this.cart_items.length;i++)
    {
      cart_items_datalayer.push({
        'name': this.cart_items[i].glass.name,      // name of a product that is displayed. Always include it.
        'id': this.cart_items[i].glass.id,     			// id of a product. Always include it.
        'price': this.cart_items[i].amount,				// price of a product
        'quantity':  this.cart_items[i].quantity,
        'brand': 'Visages',				// brand/vendor of a product
        'category': 'Eyewear'			// category of a product
        //'variant': 'Silver',       		// product variant. If there are no variants, exclude this key from the dataLayer.push
        
      })
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'eec.checkout',				// name of an event. In this case, always stays as eec.checkout
        'ecommerce': {							// ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
            'currencyCode': 'EUR',		// the currency which is currently used by a visitor
            'checkout': {						// name of an action. In this case, always stays as checkout
                'actionField': {	
                    'step': 1					// number of the checkout step that a user has entered (must always be 1 in this situation) 
                },
                'products': cart_items_datalayer
            }
        }
    });

    console.log('datalayer checkout ')
    console.log(cart_items_datalayer)
    console.log(window.dataLayer)
    
    
   
    jQuery(window).resize(function(){
        if(window.innerWidth>=1025) {
            jQuery('.order-review #order-review-content').css('display','block');
        }
       
    }); 

    this.loaded=true


   
  }
  
}
</script>
<style  scoped>
@media screen and (max-width:760px) {

    .delivery-title
    {
        width:40% !important;
    }
    body .order-review {
        margin-top:20px;
        width:90%;
        border:0 !important;
        margin-left:5%;
        background: rgba(248, 248, 248, 1) 0% 0% no-repeat padding-box;
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.29);
    }
    .checkout-wrapper>div {
        display:flex;
        flex-direction: column;
    }
    .checkout-wrapper {
        margin-top:70px !important;
    }
}
#pickup-list table td,
#pickup-list table tr,
#pickup-list table
{
	text-align:left;
	border:0;
}
#pickup-list
{
	text-align:left;
	margin-bottom:20px;
	max-height:350px;
	overflow:auto;
}
#pickup-modal #widget-container
{
	width:70%;
	max-width:1100px;
	overflow:auto;
	position:absolute;
	max-height:100vh;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
}
#pickup-modal
{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.5);
	
	z-index:999999;
}
.delivery-step
{
    display: block;
    margin-bottom:3px;
}
.delivery-steps 
{
    font-size:12px;
    text-align: left;
}
.delivery-steps .number
{
    background:#000;
    border-radius:50%;
    padding:5px 8px;
    display: inline-block;
    margin-right:1px;
    font-size:9px;
    color:#fff;
}
#popup-payment
{
    display:block;
}
.product-detail .subtotal
{
    float:right;
    clear:none;
    width:auto;
    display: inline-block;
}
.product-detail
{
    clear:both;
    display:block;
    width:100%;
}



</style>