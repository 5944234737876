<template>
  <div id="pickup_list" v-if="displayPickups">
    <div v-if="pickup_list.length==0">
      Veuillez compléter votre adresse.
    </div>
    <div v-else>
     <b>Points relais à priximité de {{address}}, {{postcode}} {{city}}</b>

      <table class="table delivery">
        <tr v-for="(pickup, index) in pickup_list" :key="index">
          <td><input type="radio" name="pickup-point" @click="selectPickup(pickup.id)" class="pickup-select" /></td>
          <td>
            <b>{{pickup.name}}</b><br />
            {{pickup.address1}}<br />
            <span v-if="pickup.address2!=''">{{pickup.address2}}<br /></span>
            {{pickup.postcode}}<br />
            {{pickup.country}}<br /><br />
            Distance : {{pickup.distance}} m
          </td>
        </tr>     
      </table>   
    </div>
  </div>
</template>

<script>
import {APIRequest} from "@simb2s/senseye-sdk";
import jQuery  from 'jquery';
import emitter from 'tiny-emitter/instance'

export default {
  components: {  },
  name: 'PickupList',
  props: ['address','city','postcode' ],
  data() {
    return {
      pickup_list:[],
      displayPickups:false,
     
    }
  },
  async mounted(){

    await this.loadPickups();
    
  },
  methods:{
    selectPickup(id)
    {
      emitter.emit('selectpickup',id);
    },
    async loadPickups(){
      let Parameters={};
      Parameters.zipCode=this.postcode;
      Parameters.city=this.city;
      Parameters.address=this.address;

      let pickup_list=await APIRequest.list_pickups(JSON.stringify(Parameters));
     
        
        for(let i=0;i<pickup_list.length;i++)
        {
          if(pickup_list[i].id!=null)
          {
            this.pickup_list.push(pickup_list[i])
          }
        }
      if(this.pickup_list.length==0)
      {

      }
      else
      {
        this.displayPickups=true
        jQuery('.pickup-select').click(function(){
          var idpickup=jQuery(this).attr('data-id');
          jQuery('#pickup_id').val(idpickup);
        });
      }
      
          
      }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
